
(function() {

	'use strict';

	var coredata = window.coredata;

	var containerCt,
		codeChars,
		timeCt,
		timeIntervalTimer,
		pusher;

	function init() {

		containerCt = $('.container');
		codeChars = $('.code-item-char');
		timeCt = $('.time span');

		getStreamingCode();
	}

	function getStreamingCode() {

		containerCt.addClass('loading');

		var jqXHR = $.ajax({
			method: 'GET',
			url: coredata.apiUrl + '/d/boards/code',
			dataType: 'json',
		});

		jqXHR.done(function(data) {
			var code = (data || {}).code;
			if (code) {
				displayCode(code);
			} else {
				logError('Invalid code returned', {
					response: JSON.stringify(data)
				});
			}
		});

		jqXHR.fail(function(jqXHR) {
			logError('Get code failed', {
				code: +jqXHR.status,
				response: jqXHR.responseText
			});
		});
	}

	function displayCode(code) {

		// show the code
		var letters = code.split('');
		codeChars.each(function(k, node) {
			$(node).text(letters[k]);
		});

		// establish pusher channel
		establishPusherChannel(code);

	}

	function establishPusherChannel(code) {

		// close previous pusher connection, if open
		if (pusher) {
			pusher.disconnect();
		}

		var channelName = 'streaming-' + code,
			options = {
				cluster: coredata.pusherCluster
			};

		options.authEndpoint = coredata.apiUrl + '/auth/pusher-streaming/' + code;

		if (!window.Pusher) {
			logError('Pusher lib not loaded');
			return;
		}

		pusher = new window.Pusher(coredata.pusherKey, options);

		if (!pusher) {
			logError('Pusher init failed');
			return;
		}

		if (!pusher.channel(channelName)) {

			var channel = pusher.subscribe(channelName);

			channel.bind('streaming', function(url) {
				redirectToUrl(url);
			});

			channel.bind('pusher:subscription_succeeded', function(){
				updateTime();
			});
		}

	}

	function updateTime() {

		if (timeIntervalTimer) {
			clearTimeout(timeIntervalTimer);
		}

		var timeLeft = 15 * 60; // 15mins in seconds

		var tickFn = function(){

			updateTimeText(timeLeft);

			if (timeLeft) {
				timeLeft--;
			} else {
				clearTimeout(timeIntervalTimer);
				getStreamingCode();
			}
		};

		tickFn();

		timeIntervalTimer = setInterval(tickFn, 1000); // every 1s

		// when done, restart timer and remove loader
		containerCt.removeClass('loading');
	}

	function updateTimeText(timeLeft) {
		var timeLeftMinutes = Math.floor(timeLeft / 60),
			timeLeftSeconds = timeLeft - (timeLeftMinutes * 60),
			timeLeftMinutesFormatted = timeLeftMinutes.toString(),
			timeLeftSecondsFormatted = timeLeftSeconds.toString();

		if (timeLeftMinutes < 10) {
			timeLeftMinutesFormatted = '0' + timeLeftMinutesFormatted;
		}
		if (timeLeftSeconds < 10) {
			timeLeftSecondsFormatted = '0' + timeLeftSecondsFormatted;
		}

		timeCt.text(timeLeftMinutesFormatted + ':' + timeLeftSecondsFormatted);
	}

	function redirectToUrl(url) {
		document.location.href = url;
	}

	function logError(text, extradata) {

		var msg = '[Stream to TV] ' + text;

		if (!extradata) {
			extradata = {};
		}

		if (typeof window.Rollbar !== 'undefined') {
			window.Rollbar.error(msg, extradata, 'critical');
		} else {
			// console.log(msg, extradata);
		}
	}

	window.addEventListener('DOMContentLoaded', init);

}());
